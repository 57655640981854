import {i18n} from '@/i18n'

export default
{
	methods:
	{
		checkFileFormat(fileList,
						supportFileFormat)		
		{
			for(var ix = 0; ix < fileList.length; ix++)
			{					
				if(!supportFileFormat.includes(fileList[ix].name.split(".").pop().toLowerCase()))
				{
					return "E_{0}_{1}".format(
										i18n.t('Message.Error.Error'),
										i18n.t('Message.Error.UnsupportedFileFormat') + " " + fileList[ix].name);
				}					
			}
			return null;
		},
		checkNamePattern(fileName)
		{
			/* Only name with alphabet, number, chinese character,'_', '-', '=', '(', ')', '.' and space*/
			let pattern = /^(\w|\.|-|=|\(|\)|\s|\p{sc=Han})+$/u;
			if(!pattern.test(fileName))
			{
				this.$Message("E_{0}_{1}".format(
								i18n.t('Message.Error.Error'),
								i18n.t('Message.Error.FilenameIncorrectPattern') + " " + fileName));
				return false;
			}
			
			let dotPattern = /^([^.]|[.](?=[^.]|$))*$/;
			if(!dotPattern.test(fileName))
			{
				this.$Message("E_{0}_{1}".format(
								i18n.t('Message.Error.Error'),
								i18n.t('Message.Error.FilenameIncorrectDotPattern') + " " + fileName));
				return false;
			}
			return true;
		},
		convertBytesToSize(	bytes, 
							decimals = 2) 
		{
			if (bytes === 0) 
			{
				return '0 Bytes';
			}
			
			const marker = 1024;
			const decimalPoint = decimals < 0 ? 0 : decimals;
			const sizeList = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const size = Math.floor(Math.log(bytes) / Math.log(marker));

			return parseFloat((bytes / Math.pow(marker, size)).toFixed(decimalPoint)) 
						+ ' ' + sizeList[size];
		}
	}
}
