import fileProvider from '@/util/file.js'

export default {
	messages:
	{
		required: `必填欄位`,
		length: (field, args) => `必須為${args[0]}字元`,
		alpha_num:`只能以字母及數字組成`,
		decimal: (field, args) => `必須是數字或是小數點${args[0]}位數以內`,
		max: (field, args) => `必須小於${args[0]}字元`,
		min: (field, args) => `必須至少為${args[0]}字元`,
		between: (field, args) => `必須介於${args[0]}和${args[1]}`,
		numeric:`必須是數字`,
		confirmed:`該密碼與新密碼不符`,
		min_value: (field, args) => `必須大於或等於${args[0]}`,
		max_value: (field, args) => `必須等於或小於${args[0]}`,
		email:`請填寫正確的電子郵件格式`,
		url:`網址必須為有效連結`,
		ip:`IP 必須是有效的 IP 位址`,
		is_not:`值必須不同`,
		regex: `格式錯誤`,
		size: (field, args) => `尺寸必須小於${fileProvider.methods.convertBytesToSize(args * 1024, 0)}`,
		excluded: `此資料已存在`
	}
}
