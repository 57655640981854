export default
{
	methods:
	{
		arrayFilterNullAndEmpty(array)
		{
			return array.filter(function (el)
								{
									return el != "" && el != null;
								});
		},
		checkNullEmptyUndef(value)
		{
			switch(value)
			{
				case undefined:
				case "undefined":
					return true;
				case null:
				case "null":
					return true;
				case "" :
					return true
				default:
					return false;
			}
		},
		convertJsType(value)
		{
			switch(value)
			{
				case "undefined":
					return undefined;
				case "null":
					return null;
				case "true":
					return true;
				case "false":
					return false;
				case "":
					return null;
				default:
					return value;
			}
		},
		compareObjByValue(key, order = 'asc')
		{
			return function innerSort(a, b)
			{
				if (!Object.prototype.hasOwnProperty.call(a, key)
						|| !Object.prototype.hasOwnProperty.call(a, key))
				{
					return 0;
				}

				const varA = (typeof a[key] === 'string')
								? a[key].toUpperCase() : a[key];
				const varB = (typeof b[key] === 'string')
								? b[key].toUpperCase() : b[key];

				let comparison = 0;
				if (varA > varB)
				{
					comparison = 1;
				}
				else if (varA < varB)
				{
					comparison = -1;
				}
				return ((order === 'desc') ? (comparison * -1) : comparison);
			};
		},
		unSelectCheckBox(name)
		{
			var ele=document.getElementsByName(name);
			for(var i=0; i<ele.length; i++)
			{
				if(ele[i].type=='checkbox')
				{
					ele[i].checked=false;
				}
			}
		},
		removeItemFromList(	arr,
							value)
		{
			var i = 0;
			while (i < arr.length)
			{
				if (arr[i] === value)
				{
					arr.splice(i, 1);
				}
				else
				{
					i++;
				}
			}
			return arr;
		},
		downloadFileByNameAndType(	response,
									fileName,
									applicationType)
		{
			const url = window.URL.createObjectURL(new Blob([response.data], {type:applicationType}));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(	'download',
								decodeURIComponent(fileName).replace(/%20/g, ' '));
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		},
		numberPad(	number,
					size)
		{
			var result = String(number);
			while (result.length < size) 
			{
				result = "0" + result;
			}
			return result;
		},
		dynamicSort(property) 
		{
			var sortOrder = 1;
			if(property[0] === "-") 
			{
				sortOrder = -1;
				property = property.substr(1);
			}
			return function (a,b) 
			{
				var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
				return result * sortOrder;
			}
		}
	}
}
