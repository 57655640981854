import Vue from 'vue'
import VueRouter from 'vue-router'
import jsHelperProvider from '@/util/jsHelper.js'
import store  from "@/store/index.js"
import $ from 'jquery'
import axios from 'axios'

Vue.use(VueRouter)

function getEipRoute(	routePath,
						routeName,
						ComponentName)
{
	const LazyComponent = () => import('@/views/' + ComponentName + '.vue');

	return {
		path: routePath,
		name: routeName,
		components:
		{
			default: LazyComponent,
			footer: () => import('@/components/layout/footer.vue'),
			header: () => import('@/components/layout/header.vue')
		}
	};
}

function getEipRouteWithMeta(	routePath,
								routeName,
								ComponentName,
								metaName)
{
	return $.extend({}, getEipRoute(routePath,
									routeName,
									ComponentName),
					{ meta: {
								title: metaName,
					}})
}

const routes = [
	{
		path: '*',
		redirect: '/UserNoRole',
	},
	{
		path: '/Login',
		name: 'Login',
		component:() => import('@/views/Login.vue')
	},
	getEipRoute('/UserNoRole',
				'UserNoRole',
				'UserNoRole'),
	getEipRoute('/Exception',
				'Exception',
				'Exception'),
	getEipRoute('/',
				'MainPage',
				'MainPage'),
	getEipRoute('/AipHomePage',
				'AipHomePage',
				'aip/HomePage'),
	getEipRouteWithMeta('/CheckInForm/:location',
						'CheckInForm',
						'CheckInForm',
						'PageTitle.CheckInForm'),
	getEipRouteWithMeta('/InitPage',
						'InitPage',
						'InitPage',
						'PageTitle.InitPage'),
	getEipRouteWithMeta('/AttendanceManagement',
						'AttendanceManagement',
						'AttendanceManagement',
						'PageTitle.EmergencyAttendanceDashboard'),
	getEipRoute('/Role',
				'Role',
				'Role'),
	getEipRouteWithMeta('/WebPageGroup',
						'WebPageGroup',
						'WebPageGroup',
						'PageTitle.WebPageGroup'),
	getEipRouteWithMeta('/WebPage',
						'WebPage',
						'CtrlWebPage',
						'PageTitle.WebPage'),
	getEipRoute('/UserManagement',
				'User',
				'User'),
	getEipRoute('/AgentflowProcess',
				'AgentflowProcess',
				'AgentflowProcess'),
]
const router = new VueRouter(
{
	base: process.env.VUE_APP_BASE_URL,
	mode: 'history',
	routes
})

var allowUrlByPath = ["/Login"];
var allowUrlByName = ["ChangePwd", "AttendanceManagement", "UserNoRole"];

router.beforeEach((	to,
					from,
					next) =>
{
	if (allowUrlByPath.indexOf(to.path) > -1
			|| allowUrlByName.indexOf(to.name) > -1)
	{
		next();
		return;
	}
	if(to.name === 'CheckInForm')
	{
		axios.post(`emergency/${to.params.location}/isEmergencyStart`)
		.then((response) =>
		{
			if(response.data)
			{
				next();
			}
			else
			{
				next('/UserNoRole');
			}
			return;
		});
	}

	if ((jsHelperProvider.methods.checkNullEmptyUndef(store.state.authorization)
			&& to.name !== 'CheckInForm'))
	{
			next({ 	name: 'Login',
					query: { from: to.path } });
	}
	else if(to.name !== 'CheckInForm')
	{
		next();
	}
})

export default router
