import $ from 'jquery'
import {i18n} from '@/i18n'
import { Validator } from 'vee-validate'
import Loading from '@/components/layout/Loading.vue'

String.prototype.format = function()
{
	var args = arguments;
	return this.replace(/\{(\d+)\}/g, function(a, num)
	{
		return args[num] || a
	})
}

String.prototype.errorMsgFromController = function()
{
	var errorMessage = JSON.parse(this);
	var message = "";

	if(errorMessage.error === undefined)
	{
		return i18n.t(errorMessage);
	}

	$.each(errorMessage.error, function(key, value)
	{
		if(message)
		{
			message += " - ";
		}
		message += i18n.t(value)
	});
	message += ": " + errorMessage.value;
	return message;
}

export default
{
	components:
	{
		Loading,
	},
	computed:
	{
		language: function ()
		{
			this.$store.commit('setAppLanguage', this.$i18n.locale);
			Validator.localize(this.$i18n.locale)
			return this.$i18n.locale;
		}
	}
}