import fileProvider from '@/util/file.js'

export default {
	messages:
	{
		required: `Can't Be Empty`,
		length: (field, args) => `Must be ${args[0]} characters`,
		alpha_num:`May only contain alpha-numeric characters`,
		decimal: (field, args) => `Must be numeric and may contain ${args[0]} decimal points`,
		max: (field, args) => `Must be less than ${args[0]} characters`,
		min: (field, args) => `Must be at least ${args[0]} characters`,
		between: (field, args) => `Must be between ${args[0]} and ${args[1]}`,
		numeric:`May only contain numeric characters`,
		confirmed:`The confirm password value is not valid`,
		min_value: (field, args) => `Must be equal or more than ${args[0]}`,
		max_value: (field, args) => `Must be equal or less than ${args[0]}`,
		email:`The email value is not valid`,
		url:`Must be a valid URL`,
		ip:`The ip must be a valid ip address`,
		is_not:`The value must be different`,
		regex: `The input value is not valid`,
		size: (field, args) => `The size must be less than ${fileProvider.methods.convertBytesToSize(args * 1024, 0)}`,
		excluded: `This information already exist`
	}
}
