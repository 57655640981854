import Vue from 'vue'
import 'mutationobserver-shim'
import App from './App.vue'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// router
import router from './router'
import VueRouter from "vue-router"
// fortawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
// msg pop up
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
// vue feature
import axios from 'axios'
import store from './store'
import { i18n } from './i18n'
import './eventBus.js'

//Validation
import VeeValidate, { Validator } from 'vee-validate'
import enUSMessages from './locales/validation_en'
import zhTWMessages from './locales/validation_zh'

//Calendar
import VCalendar from 'v-calendar';

//Datatable
require('datatables.net-dt')
require( 'datatables.net-scroller' )
require('datatables.net-buttons-dt')
import 'datatables.net-fixedcolumns-dt'
import 'datatables.net-plugins/sorting/absolute.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.flash.min'
import 'datatables.net-dt/css/jquery.dataTables.css'

// vue-multiselect
import 'vue-multiselect/dist/vue-multiselect.min.css'

// scroll
import "scroll-behavior-polyfill";

//CSS
import './css/common.css'
import './css/main.css'
import './css/plugin.scss'
import './css/common.scss'

Vue.use(VCalendar);
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(require('vue-moment'));
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

axios.defaults.baseURL = process.env.VUE_APP_CONTROLLER_URL;

axios.defaults.headers.common['Authorization'] = store.state.authorization;

const msgOptions = {
	toast:
	{
		position: SnotifyPosition.rightBottom,
		timeout: 5000,
		closeOnClick:false
	}
}
Vue.use(Snotify,
		msgOptions)

Vue.prototype.$Message =function (msg)
{
	var msgInfo = msg.split('_');
	if (msgInfo[0] === "E")
	{
		this.$snotify.error(msgInfo[2],
							msgInfo[1]);
	}
	else if (msgInfo[0] === "S")
	{
		this.$snotify.success(	msgInfo[2],
								msgInfo[1]);
	}
	else
	{
		var html = "<div class=\"snotifyToast__title\">" + msgInfo[0] +"</div>\n <div class=\"snotifyToast__body\">" + msgInfo[1] +"</div>";
		this.$snotify.html(	html,
							{type: 'warning'});
	}
}

Vue.prototype.$checkStatus = function(	error,
										messageHeader)
{
	var responseStatus = error.status;
	var message = error;
	if(error.response != undefined)
	{
		responseStatus = error.response.status;
		message = error.response.data;
		if (Object.prototype.toString.call(message) === "[object ArrayBuffer]")
		{
			message = new TextDecoder().decode(error.response.data)
		}
	}

	switch(responseStatus)
	{
		case 403:
			router.push({path: '/UserNoRole'});
			break;

		case 401:
		case 504:
			store.commit('setTokenLogin', '');
			localStorage.removeItem('authorization')
			delete this.$http.defaults.headers.common['Authorization']
			this.$http.post('/auth/logout')
				.then(() =>
				{
					router.push({ path: '/Login' })
				})
				.catch(() =>
				{
					router.push({ path: '/Login' })
				});
			break;

		case 500:
			return this.$Message("E_{0}_{1}".format(
					i18n.t(messageHeader),
					JSON.stringify(message).errorMsgFromController()))

		case 417:
			router.push({	path: '/Exception',
							query : {exception: error.response.data}});
			break;

		case 423:
			router.push({path: message});
			break;

		default:
			console.log(message);
			break;
	}
}

Vue.prototype.$discardChange = function(confirmModal,
										changeModel)
{
	this.$refs[confirmModal].hide();
	this.$refs[changeModel].hide();
}

Vue.prototype.$closeModel = function(model)
{
	this.$refs[model].hide();
}

Vue.prototype.$urlBase64toFile = function(	url,
											filename,
											mimeType)
{
	return (fetch(url)
			.then(function(res){return res.arrayBuffer();})
			.then(function(buf){return new File([buf],
												filename,
												{type:mimeType});
			}));
}

Vue.prototype.$loading = (visible) =>
{
	document.getElementById('loading').style.display = visible ? 'block' : 'none';
}

var config = {
	locale: store.state.appLanguage,
	events: 'input|blur',
	dictionary: {	zh:zhTWMessages,
					en:enUSMessages}
}

Vue.use(VeeValidate,
		config)

Validator.localize({zh: zhTWMessages,
					en: enUSMessages})

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
