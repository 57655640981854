import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store  from "./store/index.js"

Vue.use(VueI18n)

export const selectedLocale = store.getters.appLanguage || checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE

function loadLocaleMessages()
{
	return 	{
		'en': Object.assign({}, require('./locales/en_US.json'), require('./locales/en_US.changelog.json')),
		'zh': Object.assign({}, require('./locales/zh_TW.json'), require('./locales/zh_TW.changelog.json'))
	}
}

export const i18n = new VueI18n({
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})

function checkDefaultLanguage()
{
  let matched = null
  let languages = Object.getOwnPropertyNames(loadLocaleMessages())
  var langNavigator = navigator.language;

  if (langNavigator == null)
  {
    langNavigator = navigator.userLanguage;
  }

  languages.forEach(lang =>
  {
    if (lang === langNavigator)
    {
      matched = lang
    }
  })

  if (!matched)
  {
      languages.forEach(lang =>
      {

        let languagePartials = langNavigator.split('-')[0]
        if (lang === languagePartials)
        {
          matched = lang
        }
    })
  }

  if (!matched)
  {
    languages.forEach(lang => {
      let languagePartials = langNavigator.split('-')[0]
      if (lang.split('-')[0] === languagePartials)
      {
        matched = lang
      }
    })
  }

  return matched
}