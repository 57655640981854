import Vue from 'vue'
import Vuex from 'vuex'
import { selectedLocale } from '@/i18n'
import createPersistedState from "vuex-persistedstate"
import CryptoJS from 'crypto-js'

Vue.use(Vuex)
const SECRETKEY = process.env.VUE_APP_SECRETKEY;

export const store = Vue.observable(
{
	isNavOpen: false
});

export default new Vuex.Store(
{
	state:
	{
		appLanguage: selectedLocale,
		isAdmin:'',
		authorization: localStorage.getItem('authorization') ? localStorage.getItem('authorization') : '',
		userId:'',
		userLocation:'',
		isSysAdmin:'',
		isManager:'false',
		userName:'',
		roleList:'',
		tokenType:''
	},
	getters:
	{
		appLanguage (state)
		{
			return state.appLanguage;
		}
	},
	mutations:
	{
		setAppLanguage(	state,
						language)
		{
			state.appLanguage = language;
		},
		toggleNav(store)
		{
			store.isNavOpen = !store.isNavOpen;
		},
		setTokenLogin(	state,
						user_authorization)
		{
			state.authorization = user_authorization;
			localStorage.setItem('authorization', user_authorization);
		},
		setTokenType(	state,
						userAuthType)
		{
			state.tokenType = userAuthType;
		},
		getUserId(	state,
					Id)
		{
			state.userId = CryptoJS.AES.encrypt(Id,
												SECRETKEY).toString();
		},
		getIsAdmin(	state,
					role)
		{
			state.isAdmin = CryptoJS.AES.encrypt(	role,
													SECRETKEY).toString();
		},
		getUserLocation(state,
						location)
		{
			state.userLocation = location;
		},
		getIsSysAdmin(	state,
						role)
		{
			state.isSysAdmin = CryptoJS.AES.encrypt(	role,
														SECRETKEY).toString();
		},
		getIsManager(	state,
						role)
		{
			state.isManager = CryptoJS.AES.encrypt(	role,
													SECRETKEY).toString();
		},
		getRoleList(state,
					role)
		{
			state.roleList = CryptoJS.AES.encrypt(	role,
													SECRETKEY).toString();
		},
		getUserName(	state,
						name)
		{
			state.userName = CryptoJS.AES.encrypt(	name,
													SECRETKEY).toString();
		}
	},
	plugins: [createPersistedState()]
})